import gql from 'graphql-tag';
import { query } from './client';

export interface CharacterRanking {
  id: number
  name: string
  spec: string
  amount: number
  report: {
    code: string
    fightID: number
    startTime: number
  }
  server: {
    id: number
    name: string,
    region: string,
  }
  bracketData: number
  aDPS: number
  rDPS: number
  nDPS: number
  pDPS: number
}

export interface CharacterRankingPagination {
  page: number,
  hasMorePages: boolean,
  count: number
  rankings: CharacterRanking[]
}

/**
 * https://ja.fflogs.com/v2-api-docs/ff/encounter.doc.html
 */
export interface Encounter {
  id: number
  name: string
  characterRankings: CharacterRankingPagination
}

/**
 * https://ja.fflogs.com/v2-api-docs/ff/zone.doc.html
 */
export interface Zone {
  id: number
  name: string
  encounters: Encounter[]
}

/**
 * https://ja.fflogs.com/v2-api-docs/ff/expansion.doc.html
 */
export interface Expansion {
  id: number
  name: string
  zones: Zone[]
}

export class WorldService {
  static async expansions(): Promise<Expansion[]> {
    const result = await query(gql`
      query {
        worldData {
          expansions {
            id
            name
            zones {
              id
              name
              encounters {
                id
                name
              }
            }
          }
        }
      }
    `);
    return result.worldData.expansions;
  }

  static async rankings(encounterId: string, specName: string, page = 1) :Promise<CharacterRanking[]> {
    let result;
    if (!specName) {
      result = await query(gql`
        query {
          worldData {
            encounter(id: ${encounterId}) {
              id
              name
              characterRankings(page: ${page})
            }
          }
        }
      `);
    } else {
      result = await query(gql`
        query {
          worldData {
            encounter(id: ${encounterId}) {
              id
              name
              characterRankings(specName: "${specName}", page: ${page})
            }
          }
        }
      `);
    }
    return result.worldData.encounter.characterRankings;
  }
}
