<template>
  <div class="project__header">
    <el-button type="success" class="ml-4" plain
      @click="showSelect = true"
    >
      <el-icon class="el-icon--left"><CirclePlus /></el-icon>
      <span class="hidden-xs-only">Add Report</span>
    </el-button>
    <el-divider direction="vertical" />
    <div class="project__description">
      <el-input
        v-model="storeProjects.description"
        size="large"
        placeholder="Description"
      />
    </div>
    <el-button style="margin-left:auto" plain
      @click="showSetting = true"
    >
      <el-icon class="el-icon--left"><Tools /></el-icon>
      <span class="hidden-xs-only">Setting</span>
    </el-button>

  </div>
  <div class="project__content">
    <template
      v-for="reportData in storeProjects.reports"
      :key="reportData.id"
    >
      <ReportPain
        class="project__content-item"
        :report-data="reportData"
        @remove="remove"
        @update-settings="updateSettings"
      />
    </template>

    <ProjectReportEmpty
      v-if="storeProjects.reports.length < 1"
      class="project__content-item" @click="showSelect = true" />
  </div>
  <ProjectReportSelectDialog v-model:show="showSelect" />
  <ProjectSettingDialog
    :settings-data="storeProjects.settings"
    v-model:show="showSetting" />
</template>

<script setup>
import {
  ref, provide,
} from 'vue';
import { CirclePlus, Tools } from '@element-plus/icons-vue';
import ReportPain from '@/components/Report/ReportPain.vue';
import ProjectReportEmpty from '@/components/Project/ProjectReportEmpty.vue';
import ProjectReportSelectDialog from '@/components/Project/ProjectReportSelectDialog.vue';
import ProjectSettingDialog from '@/components/Project/ProjectSettingDialog.vue';

import useSettings from '@/store/settings';
import useProjects from '@/store/projects';

const storeSettings = useSettings();
const storeProjects = useProjects();

/**
 * スクロールシンク
 */
provide('sync-scroll', storeSettings);

const showSelect = ref(false);
const showSetting = ref(false);

/**
 * レポート削除
 */
const remove = (reportData) => {
  storeProjects.removeReport(reportData);
};

const updateSettings = ({ reportData, settings }) => {
  storeProjects.updateSettings(reportData, settings);
};

</script>

<style scoped>
.project__header {
  display: flex;
  align-items: center;
}
.project__description :deep( .el-input__inner ) {
  font-weight: 600;
  font-size: 18px;
  color: var(--el-text-color-regular);
}

.project__description :deep( .el-input__wrapper ) {
  box-shadow: none;
}
.project__description :deep( .el-input__wrapper:hover ) {
  box-shadow: 0 0 0 1px var(--el-input-hover-border-color) inset;
}

.project__content {
  display: flex;
  margin-top: 15px;
  gap: 20px;
}

.project__content-item {
  min-width: 300px;
  max-width: 1000px;
  height: calc( 100vh - 160px );
  min-height: 500px;
  flex-grow: 1;
}

</style>
