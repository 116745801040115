import { Report, ReportEvent } from '@/services/report';
import {
  AbilityData, BandsData, FightData,
} from '@/types/report.d';

/**
 * Buff, Debuff情報作成
 * @param events Buffs,Debuffsイベント配列
 * @param abilitiesData アビリティデータ
 * @param fightsData 戦闘データ
 * @returns
 */
const createBandsData = (
  events: Array<ReportEvent>,
  abilitiesData: Array<AbilityData>,
  fightsData: FightData,
): BandsData[] => {
  const list: BandsData[] = [];

  events.forEach((event) => {
    // アビリティに該当するBandsDataを取得する
    let item = list.find((b) => b.gameID === event.abilityGameID);
    if (!item) {
      // なければ新規追加
      const master = abilitiesData.find((m) => m.gameID === event.abilityGameID);
      item = <BandsData>{
        gameID: event.abilityGameID,
        name: master?.name || '',
        icon: master?.icon || '',
        bands: [],
      };
      list.push(item);
    }

    // イベントタイプに応じてBandDataを編集
    if (event.type === 'applybuff' || event.type === 'applydebuff') {
      // 1.appybuff
      // Bandを追加
      item.bands.push({
        startTime: event.timestamp,
        endTime: event.timestamp + event.duration,
      });
    } else if (event.type === 'removebuff' || event.type === 'removedebuff') {
      // 2.removebuff
      const exists = item.bands.find((e) => e.startTime <= event.timestamp
        && event.timestamp <= e.endTime);
      if (exists) {
        // Bandを切る
        exists.endTime = event.timestamp;
      } else {
        console.warn(`${event.type} - not found band at id:${event.abilityGameID} timestamp:${event.timestamp}`);
      }
    } else if (event.type === 'refreshbuff' || event.type === 'refreshdebuff') {
      // 3.refreshbuff
      const exists = item.bands.find((e) => e.startTime <= event.timestamp
        && event.timestamp <= e.endTime);
      if (exists) {
        // Bandを延長
        exists.endTime = event.timestamp + event.duration;
      } else {
        console.warn(`${event.type} - not found band at id:${event.abilityGameID} timestamp:${event.timestamp}`);
      }
    } else if (event.type === 'applybuffstack' || event.type === 'applydebuffstack') {
      // 4.applybuffstack
      const exists = item.bands.find((e) => e.startTime <= event.timestamp
        && event.timestamp <= e.endTime);
      if (exists) {
        if (event.timestamp === exists.startTime) {
          // 同時パターン Stack更新のみ
          exists.stack = event.stack;
        } else {
          // Bandを分割
          item.bands.push({
            startTime: event.timestamp,
            endTime: exists.endTime,
            stack: event.stack,
          });
          exists.endTime = event.timestamp;
        }
      }
    } else if (event.type === 'removebuffstack' || event.type === 'removedebuffstack') {
      // 5.removebuffstack
      const exists = item.bands.find((e) => e.startTime <= event.timestamp
        && event.timestamp <= e.endTime);
      if (exists) {
        // Bandを分割
        item.bands.push({
          startTime: event.timestamp,
          endTime: exists.endTime,
          stack: event.stack,
        });
        exists.endTime = event.timestamp;
      }
    } else {
      console.warn(`unknown event type :${event.type}`);
    }
  });

  list.forEach((buff) => {
    buff.bands.forEach((b) => {
      const band = b;
      if (band.endTime > fightsData.endTime) {
        // はみ出し部分を切る
        band.endTime = fightsData.endTime;
      }
      // 開始時間からのoffset時間で更新
      band.startTime -= fightsData.startTime;
      band.endTime -= fightsData.startTime;
    });
  });
  list.sort((a, b) => (a.gameID > b.gameID ? -1 : 1));
  return list;
};

export default class BuffsBands {
  public static parse(
    report: Report,
    events: Array<ReportEvent>,
  ) {
    const { abilities } = report.masterData;
    const fight = report.fights[0];
    console.log('parse');
    return createBandsData(events, abilities, fight);
  }

  public static create(
    reportData: any,
  ) {
    const { abilities, fightData, buffEvents } = reportData;
    return createBandsData(buffEvents, abilities, fightData);
  }
}
