import { Report, ReportActor, ReportEvent } from '@/services/report';
import { AbilityData, EventData, FightData } from '@/types/report.d';
import { getJobActionConfig } from '@/utils/config';
import { ActionConfig } from '@/utils/config/types';

/**
 * begincastイベントとcastイベントを統合する
 * @param events イベント配列
 * @returns events イベント配列
 */
const combineBeginCast = (events: ReportEvent[]) => {
  const combined: EventData[] = [];
  let preCast: EventData | null = null;
  events.forEach((event) => {
    if (event.type === 'begincast') {
      // キャスト開始イベントを一時保存
      preCast = event;
      combined.push(event);
    } else if (event.type === 'cast' && preCast !== null) {
      const duration = preCast.duration || 0;
      if (event.abilityGameID === preCast.abilityGameID
        && preCast.timestamp < event.timestamp
        && event.timestamp <= (preCast.timestamp + duration)) {
        // 前のキャスト開始イベントと紐づいたらこのイベントはスキップ
        preCast = null;
      } else {
        // アビリティが違うorキャスト時間外は前のキャスト開始を失敗扱い
        preCast.canceled = true;
        combined.push(event);
      }
    } else {
      // 前キャストなし
      combined.push(event);
    }
  });
  return combined;
};

/**
 * イベントにマスタ情報をマージする
 * @param events イベント配列
 * @param abilities アビリティマスタ
 * @returns
 */
const margeMaster = (
  events: Array<EventData>,
  abilities: Array<AbilityData>,
  fight: FightData,
): Array<EventData> => events.map((event) => {
  const master: AbilityData | undefined = abilities.find((a) => a.gameID === event.abilityGameID);
  return {
    ...event,
    name: master?.name,
    abilityType: master?.type,
    icon: master?.icon,
    time: (event.timestamp - fight.startTime),
  };
});

/**
 * イベントにマスタ情報と設定情報をマージする
 * @param events イベント配列
 * @param config ジョブ毎のステータスとアクションの設定
 * @returns
 */
const margeConfig = (
  events: Array<EventData>,
  configs: ActionConfig[],
): Array<EventData> => events.map((event) => {
  const actionConfig = configs.find((a) => a.id === event.abilityGameID);
  return {
    ...event,
    gcd: actionConfig?.gcd,
  };
});

export default class CastsTimes {
  public static parse(
    report: Report,
    events: Array<ReportEvent>,
    actor: ReportActor,
  ): Array<EventData> {
    const { abilities } = report.masterData;
    const fight = report.fights[0];
    const configs = getJobActionConfig(actor.subType);

    // castとbegincastレコードを紐づけする
    const combined = combineBeginCast(events);

    const merged = margeMaster(combined, abilities, fight);

    const configed = margeConfig(merged, configs);

    return configed;
  }

  public static create(
    reportData: any,
  ) {
    const {
      abilities, fightData, actorData, castsEvents,
    } = reportData;

    const configs = getJobActionConfig(actorData.subType);

    // castとbegincastレコードを紐づけする
    const combined = combineBeginCast(castsEvents);

    const merged = margeMaster(combined, abilities, fightData);

    const configed = margeConfig(merged, configs);

    return configed;
  }
}
