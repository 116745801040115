<template>
  <el-drawer
    v-model="showDrawer"
    title="Projects"
    :modal="false"
    :size="drawerWidth"
  >
    <el-table
      :data="storeProjects.projects"
      stripe
    >
      <el-table-column type="expand" width="50">
        <template #default="props">
          <div m="4">
            <h3> {{ props.row.description }}</h3>
            <el-button @click="select(props.row)">View</el-button>
            <el-button @click="remove(props.row)">Delete</el-button>
            <el-table max-height="300"
              :data="props.row.reports">
              <el-table-column label="Rank" prop="actorData.rank" />
              <el-table-column label="Job" prop="actorData.icon" />
              <el-table-column label="Name" prop="actorData.name" />
              <el-table-column label="Server" prop="actorData.server" />
              <el-table-column label="Boss" prop="fightData.name" />
            </el-table>
            <p m="t-0 b-2">Update: {{ new Date(Date.parse(props.row.update_time)).toLocaleString() }}</p>
            <p m="t-0 b-2">Create: {{ new Date(Date.parse(props.row.update_time)).toLocaleString() }}</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Project Name">
        <template #default="scope">
          <el-link type="primary" @click="select(scope.row)">{{scope.row.description}}</el-link>
        </template>
      </el-table-column>
      <el-table-column label="Report" prop="reports.length" />
    </el-table>
  </el-drawer>
</template>

<script setup>
import { computed } from 'vue';
import { useWindowSize } from '@vueuse/core';
import useProjects from '@/store/projects';

const { width } = useWindowSize();

const drawerWidth = computed(() => {
  if (width.value > 1400) {
    return '30%';
  }
  return 400;
});

const storeProjects = useProjects();

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:show']);

const showDrawer = computed({
  set: (val) => {
    emit('update:show', val);
  },
  get: () => props.show,
});

const select = (row) => {
  storeProjects.select(row.project_id);
  // showDrawer.value = false;
};

const remove = (row) => {
  storeProjects.removeProject(row.project_id);
};
</script>

<style scoped>

</style>
