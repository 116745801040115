import Dexie, { liveQuery } from 'dexie';
import { nanoid } from 'nanoid';
import { ReportData } from '@/types/report.d';
import { createDefaultSettings } from '@/utils/config';

import {
  onUnmounted, ref, Ref, watch,
} from 'vue';

export interface CurrentTable {
  id: number
  project_id: string
}

export interface ProjectTable {
  project_id: string
  reports: ReportData[]
  settings: unknown
  description: string
  update_time: string
  create_time: string
}

export interface SettingTable {
  sync: boolean
}

export const db = new Dexie('project2');
db.version(1).stores({
  project: 'project_id, reports, settings, description, update_time, create_time',
  current: 'id, project_id',
  settings: 'id, sync',
});

db.on('populate', (tx) => {
  const project_id = nanoid();
  tx.table('current').add({
    id: 1,
    project_id,
  });

  tx.table('project').add({
    project_id,
    reports: [],
    settings: createDefaultSettings(),
    description: 'Project - 1',
    update_time: null,
    create_time: new Date().toISOString(),
  });

  tx.table('settings').add({ id: 1, sync: false });
});

/**
 * https://github.com/dexie/Dexie.js/issues/1528
 */
export interface UseObservableOptions {
  onError?: (err: any) => void;
}

/**
 * https://github.com/dexie/Dexie.js/issues/1528
 * @param querier
 * @param deps
 * @param options
 * @returns
 */
export function useLiveQuery<T>(
  querier: () => T | Promise<T>,
  deps: Ref<any>[],
  options?: UseObservableOptions,
): Readonly<Ref<T>> {
  const value = ref<T | undefined>();
  const observable = liveQuery<T>(querier);
  let subscription = observable.subscribe({
    next: (val) => {
      value.value = val;
    },
    error: options?.onError,
  });

  watch(deps, () => {
    subscription.unsubscribe();
    subscription = observable.subscribe({
      next: (val) => {
        value.value = val;
      },
      error: options?.onError,
    });
  });

  onUnmounted(() => {
    subscription.unsubscribe();
  });
  return value as Readonly<Ref<T>>;
}
