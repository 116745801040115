import { EventData } from '@/types/report.d';
import { getJobActionConfig } from '@/utils/config';
import { ActionConfig } from '@/utils/config/types';

/**
 * イベントにマスタ情報と設定情報をマージする
 * @param events イベント配列
 * @param config ジョブ毎のステータスとアクションの設定
 * @returns
 */
const margeConfig = (
  events: Array<EventData>,
  configs: ActionConfig[],
): any => events.map((event) => {
  const actionConfig = configs.find((a) => a.id === event.abilityGameID);
  return {
    ...event,
    gcd: actionConfig?.gcd,
  };
});

export default class GcdCalcurate {
  public static calculate(
    reportData: any,
  ) {
    const {
      actorData, castsEvents,
    } = reportData;

    const exclusive = [
      2259, 2261, 2263, 2265, 2266, 2267, 2268, 2269, 2270, 2271, 2272,
      3546,
      7487, 7489, 25781,
      16491, 16492, 18805, 18806, 18807, 18873, 18874, 18875, 18876, 18877, 18878, 18879, 18880, 18881,
      24395, 24396, 24398,
    ];

    const configs = getJobActionConfig(actorData.subType);

    const configed = margeConfig(castsEvents, configs);

    const gcd = configed.filter((e: any) => e.type === 'cast' && e.gcd && !exclusive.includes(e.abilityGameID));

    let pre: any = null;
    const durations: number[] = [];
    gcd.forEach((e: any) => {
      if (!pre) {
        pre = e;
      } else {
        durations.push(e.timestamp - pre.timestamp + 6);
        pre = e;
      }
    });
    const min = Math.min(...durations);
    return Math.ceil(min / 10) / 100;
  }
}
