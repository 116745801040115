<template>
  <div class="report__header"
    :class="role">
    <div class="report__enemy">
      <el-tooltip
        class="box-item"
        :content="reportData.fightData.name"
        placement="left-start"
      >
        <el-image
          :src="`https://assets.rpglogs.com/img/ff/bosses/${reportData.fightData.encounterID}-icon.jpg`"
          :alt="reportData.fightData.name"
          fit="cover"
          style="width: 100px; height: 80px"
        />
      </el-tooltip>
      <span>{{reportData.fightData.name}}</span>
    </div>
    <div class="report__actor">
      <img
        :src="`https://assets.rpglogs.com/img/ff/icons/${reportData.actorData.icon}.png`"
        :alt="reportData.actorData.icon"
      />
      <span>{{reportData.actorData.name}}</span>
      <span style="margin-left: 10px">({{reportData.actorData.rank}})</span>
    </div>
    <div class="report__actor-info">{{gcd}}</div>

    <div class="report__operation">
      <el-dropdown size="large">
        <el-button class="report__operation-btn" :icon="MoreFilled" plain />
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item @click="reportLink">
              <el-icon class="el-icon--left"><Link /></el-icon>
              Logs
            </el-dropdown-item>
            <el-dropdown-item @click="characterLink" v-if="reportData.actorData.server !== null">
              <el-icon class="el-icon--left"><Link /></el-icon>
              Character
            </el-dropdown-item>
            <el-dropdown-item @click="showSetting = true">
              <el-icon class="el-icon--left"><Tools /></el-icon>
              Setting
            </el-dropdown-item>

            <el-dropdown-item divided @click="remove">
              <el-icon class="el-icon--left"><Delete /></el-icon>
              Delete
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>

  </div>
  <ReportSettingDialog v-model:show="showSetting" :settings-data="reportData.settings" />
</template>

<script setup>
import {
  inject, ref, computed,
} from 'vue';
import {
  Delete, MoreFilled, Tools, Link,
} from '@element-plus/icons-vue';
import classes from '@/assets/config/classes.json';
import regions from '@/assets/config/regions.json';
import GcdCalcurate from '@/logic/GcdCalcurate';
import ReportSettingDialog from './ReportSettingDialog.vue';

const emit = defineEmits(['remove']);

const useProps = inject('use-props');

const baseProps = useProps();

const reportData = computed(() => baseProps.reportData);

const showSetting = ref(false);

const gcd = GcdCalcurate.calculate(baseProps.reportData);

const role = computed(() => {
  const found = classes.find((e) => e.slug === baseProps.reportData.actorData.subType);
  console.log('found', found);
  if (found.role === 'Tank') {
    return 'tank';
  } if (found.role === 'Healer') {
    return 'healer';
  }
  return 'dps';
});

const remove = () => {
  emit('remove', reportData);
};

const reportLink = () => {
  const url = 'https://ja.fflogs.com/reports/'
      + `${reportData.value?.code}#fight=${reportData.value?.fightData?.id}&source=${reportData.value?.actorData?.id}`;
  window.open(url, '_blank');
};

const characterLink = () => {
  const region = regions
    .find((r) => r.servers.data.find((server) => server.name === reportData.value?.actorData?.server));
  const url = 'https://ja.fflogs.com/character/'
      + `${region.slug}/${reportData.value?.actorData?.server}/${reportData.value?.actorData?.name}/`;
  window.open(url, '_blank');
};

</script>

<style scoped>
.report__header {
  position: relative;
  height: 80px;
  margin: -18px -20px;
  /*background: linear-gradient(to bottom right, #B71C1C, #424242);*/
  /*background: linear-gradient(to right, #B71C1C 0% 30%, black 70% 100%);*/
  /*color: var(--el-text-color-regular);
  */
  color: white;
}
.report__header.healer {
  background: linear-gradient(to right, #1B5E20 0% 30%, black 70% 100%);
}
.report__header.tank {
  background: linear-gradient(to right, #0D47A1 0% 30%, black 70% 100%);
}
.report__header.dps {
  background: linear-gradient(to right, #B71C1C 0% 30%, black 70% 100%);
}
.report__enemy {
  height: 80px;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
}

.report__enemy :deep(.el-image) {
  position: absolute;
  top: 0;
  right: 40px;
  opacity: 0.6;
}

.report__enemy span {
  display: none;
  position: absolute;
  bottom: 10px;
  right: 120px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.report__actor {
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  align-items: center;
}
.report__actor img {
  margin-right: 15px;
}
.report__actor span {
  font-weight: 600;
}
.report__actor-info {
  position: absolute;
  bottom: 10px;
  left:10px;
}
.report__operation {
  position: absolute;
  top: 5px;
  right: 5px;
}

.report__operation-btn {
  /*display: none;*/
  width: 32px;
  opacity: 0.2;
}
.report__header:hover .report__operation-btn {
  opacity: 0.8;
}

</style>
