import { Report, ReportEvent } from '@/services/report';
import { AbilityData, EventData, FightData } from '@/types/report.d';

const removeUnnecessaryEvent = (events: Array<EventData>, abilities: Array<AbilityData>) => {
  const filtered: Array<EventData> = [];
  events.forEach((event) => {
    const master: AbilityData | undefined = abilities.find((a) => a.gameID === event.abilityGameID);
    if (/^unknown_/.test(master?.name || '')) {
      return;
    }
    const duplicate = filtered.find((e) => event.timestamp === e.timestamp);
    if (duplicate) {
      return;
    }
    const exists = filtered.find((e) => {
      const em: AbilityData | undefined = abilities.find((a) => a.gameID === e.abilityGameID);
      return (em?.name === master?.name && (event.timestamp - 2000) < e.timestamp);
    });
    if (exists) {
      return;
    }
    filtered.push(event);
  });
  return filtered;
};

/**
 * begincastイベントとcastイベントを統合する
 * @param events イベント配列
 * @returns events イベント配列
 */
const combineBeginCast = (events: Array<EventData>) => {
  const combined: Array<EventData> = [];
  let preCast: EventData | null = null;
  events.forEach((event) => {
    if (event.type === 'begincast') {
      // キャスト開始イベントを一時保存
      preCast = event;
      combined.push(event);
    } else if (event.type === 'cast' && preCast !== null) {
      const duration = preCast.duration || 0;
      if (event.abilityGameID === preCast.abilityGameID
        && preCast.timestamp < event.timestamp
        && event.timestamp <= (preCast.timestamp + duration)) {
        // 前のキャスト開始イベントと紐づいたらこのイベントはスキップ
        preCast = null;
      } else {
        // アビリティが違うorキャスト時間外は前のキャスト開始を失敗扱い
        preCast.canceled = true;
        combined.push(event);
      }
    } else {
      // 前キャストなし
      combined.push(event);
    }
  });
  return combined;
};

/**
 * イベントにマスタ情報をマージする
 * @param events イベント配列
 * @param abilities アビリティマスタ
 * @returns
 */
const margeMaster = (
  events: Array<EventData>,
  abilities: Array<AbilityData>,
  fight: FightData,
): Array<EventData> => events.map((event) => {
  const master: AbilityData | undefined = abilities.find((a) => a.gameID === event.abilityGameID);
  return {
    ...event,
    name: master?.name,
    abilityType: master?.type,
    icon: master?.icon,
    time: (event.timestamp - fight.startTime),
  };
});

export default class EnemiesTimes {
  public static parse(
    report: Report,
    events: Array<ReportEvent>,
  ): Array<EventData> {
    const { abilities } = report.masterData;
    const fight = report.fights[0];

    const filtered = removeUnnecessaryEvent(events, abilities);
    // castとbegincastレコードを紐づけする
    const combined = combineBeginCast(filtered);

    const merged = margeMaster(combined, abilities, fight);

    return merged;
  }

  public static create(
    reportData: any,
  ) {
    const {
      abilities, fightData, enemiesEvents,
    } = reportData;

    const filtered = removeUnnecessaryEvent(enemiesEvents, abilities);
    // castとbegincastレコードを紐づけする
    const combined = combineBeginCast(filtered);

    const merged = margeMaster(combined, abilities, fightData);

    return merged;
  }
}
