import { defineStore } from 'pinia';
import { nanoid } from 'nanoid';
import {
  Ref, reactive, watch, toRefs,
} from 'vue';
import {
  db, CurrentTable, ProjectTable, useLiveQuery,
} from '@/database';
import { liveQuery } from 'dexie';
import { useObservable } from '@vueuse/rxjs';
import {
  ReportData, SettingsData,
} from '@/types/report.d';
import { deepClone } from '@/utils';
import { createDefaultSettings } from '@/utils/config';

export default defineStore('projects', () => {
  // 現在のプロジェクトID
  const current: CurrentTable = reactive({ id: 1, project_id: '' });

  // project一覧
  const projects: Ref<ProjectTable[]> = useObservable(
    liveQuery(() => db.table('project').orderBy('create_time').toArray()) as any,
  );

  // 現在のプロジェクト
  const project: ProjectTable = reactive({
    project_id: '',
    reports: [],
    settings: {},
    description: '',
    update_time: '',
    create_time: '',
  });

  /**
   * DBへの更新
   */
  const update = () => {
    console.log('update project');
    const clone = <ProjectTable>deepClone(project);
    clone.update_time = new Date().toISOString();
    db.table('project').put(clone);
  };
  let unwatch = watch(project, update);

  /**
   * DB更新を検出
   */
  const { project_id } = toRefs(current);
  const raw = useLiveQuery<ProjectTable>(() => db.table('project').get(project_id.value) as any, [project_id]);
  watch(raw, (rec) => {
    unwatch();
    console.log('watch raw');
    Object.assign(project, rec);
    unwatch = watch(project, update);
  });

  /**
   * CurrentDB読み込み
   */
  const load = async () => {
    const row = await db.table('current').get(1);
    Object.assign(current, row);
    watch(current, (data) => {
      db.table('current').put(deepClone(data));
    });
  };

  /**
   * 現在のプロジェクトへレポートの追加
   * @param report レポートデータ
   */
  const addReport = (report: ReportData) => {
    project.reports.push(report);
  };

  /**
   * 現在のプロジェクトからレポートの削除
   * @param report レポートデータ
   */
  const removeReport = (report: ReportData) => {
    const index = project.reports.findIndex((r) => r.id === report.id);
    project.reports.splice(index, 1);
  };

  /**
   * 現在のプロジェクトの概要を更新
   * @param value 概要
   */
  const updateDescription = (value: string) => {
    project.description = value;
  };

  /**
   * プロジェクトの追加
   */
  const addNewProject = (index: number | undefined = undefined) => {
    console.log('addNewProject', index);
    let num = index;
    if (index === undefined) {
      num = projects.value.length + 1;
    }
    // 空プロジェクトを作成
    const newId = nanoid();
    project.project_id = newId;
    project.reports = [];
    project.settings = createDefaultSettings();
    project.description = `Project - ${num}`;
    project.update_time = new Date().toISOString();
    project.create_time = new Date().toISOString();

    current.project_id = newId;
  };

  /**
   * プロジェクトの選択
   * @param project_id プロジェクトID
   */
  const select = async (id: string) => {
    current.project_id = id;
  };

  /**
   * プロジェクトの選択
   * @param project_id プロジェクトID
   */
  const removeProject = async (id: string) => {
    db.table('project').delete(id);

    const filtered = projects.value.filter((p) => p.project_id !== id);
    if (filtered.length > 0) {
      if (current.project_id === id) {
        current.project_id = filtered[0].project_id;
      }
    } else {
      addNewProject(1);
    }
  };

  /**
   * レポートの設定更新
   * @param reportData レポートデータ
   * @param settings レポート設定データ
   * @returns
   */
  const updateSettings = async (reportData: ReportData, settings: SettingsData) => {
    const report = project.reports.find((r) => r.id === reportData.id);
    if (!report) {
      console.warn('updateSettings not found report', reportData, settings);
      return;
    }
    const index = project.reports.findIndex((r) => r.id === reportData.id);
    project.reports[index].settings = settings;
    project.update_time = new Date().toISOString();
  };

  load();

  return {
    ...toRefs(project),
    load,
    addNewProject,
    removeProject,
    addReport,
    removeReport,
    select,
    updateDescription,
    updateSettings,
    projects,
  };
});
