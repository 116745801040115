export const createDefaultSettings = () => {
  const settings = {
    casts: {
      display: true,
      specs: [],
      showname: false,
      showtime: false,
    },
    recasts: {
      self: true,
      display: true,
      abilities: [
        7396,
        7395,
        69,
        25766,
        1000049,
        7546,
        2248,
        2262,
        2264,
        3566,
        2260,
        16493,
        2258,
        16489,
        // BLM
        155,
        158,
        3573,
        7421,
        25796,
        // NIN
        7549,
        36958,
        7403,

      ],
    },
    buffs: {
      self: false,
      display: true,
      abilities: [
        1001181,
        1002687,
        1000110,
        1000049,
        1003001,
        1000841,
        1001878,
        1000141,
        1002722,
        1000738, // blm
        1001972,
        1000742,
        1000786,
        1001864,
        1001910,
        1000116,
        1001946,
        1000851,
        1001185,
        1001954,
        1002723,
        1000497,
        1000507,
        1000076,
        1001902,
        1001368,
        1000304,
        1002703,
        1002701,
        1001177,
        1000157,
        1001239,
        1001238,
        1001297,
        1001233,
        1001298,
        1001299,
        1002959,
        1001236,
        1001822,
        1001825,
        1001842,
        1002686,
        1002592,
        1003848,
      ],
    },
    debuffs: {
      self: false,
      display: true,
      abilities: [
        1001221,
        1003849,
        1003906,
        1003871,
      ],
    },
    enemies: {
      self: true,
      display: true,
      abilities: [],
      showname: true,
      showtime: true,
    },
    resources: {
      self: false,
      display: true,
      abilities: [],
    },
  };
  return settings;
};

export {
  getAllActionConfig,
  getJobActionConfig,
  getAllJobActionConfig,
} from './actions';
export {
  getAllBuffConfig,
  getJobBuffConfig,
  getAllJobBuffConfig,
} from './buffs';
export {
  getAllDebuffConfig,
  getJobDebuffConfig,
  getAllJobDebuffConfig,
} from './debuffs';
