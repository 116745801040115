import { Report, ReportActor, ReportEvent } from '@/services/report';
import { ActionConfig } from '@/utils/config/types';
import {
  AbilityData, FightData, BandsData, EventData,
} from '@/types/report.d';
import { getJobActionConfig } from '@/utils/config';

/**
 * event配列のbegincastイベントとcastイベントを統合する.
 * @param events イベント配列
 * @returns events イベント配列
 */
const combineBeginCast = (events: ReportEvent[]) => {
  const combined: EventData[] = [];
  let preCast: EventData | null = null;
  events.forEach((event) => {
    if (event.type === 'begincast') {
      // キャスト開始イベントを一時保存
      preCast = event;
      combined.push(event);
    } else if (event.type === 'cast' && preCast !== null) {
      const duration = preCast.duration || 0;
      if (event.abilityGameID === preCast.abilityGameID
        && preCast.timestamp < event.timestamp
        && event.timestamp <= (preCast.timestamp + duration)) {
        // 前のキャスト開始イベントと紐づいたらこのイベントはスキップ
        preCast = null;
      } else {
        // アビリティが違うorキャスト時間外は前のキャスト開始を失敗扱い
        preCast.canceled = true;
        combined.push(event);
      }
    } else {
      // 前キャストなし
      combined.push(event);
    }
  });
  return combined;
};

/**
 * リキャストバンド作成
 * @param events
 * @param abilitiesData
 * @param fightsData
 * @param configs
 * @returns
 */
const createRecastBandsData = (
  events: Array<ReportEvent>,
  abilitiesData: Array<AbilityData>,
  fightsData: FightData,
  configs: ActionConfig[],
): BandsData[] => {
  const list: BandsData[] = [];

  // イベント整理
  const combined = combineBeginCast(events);

  // イベントから生成
  combined.forEach((event) => {
    let { abilityGameID } = event;

    // 強化薬はID入れ替え
    if (abilityGameID > 3000000) {
      // 強化薬
      abilityGameID = 1000049;
    }

    // アクションの設定を取得
    const config = configs.find((c) => c.id === abilityGameID);
    if (config === undefined) {
      console.warn(`not found config ${abilityGameID} ${event.name}`);
      return;
    }

    // ジョブアクションの固有拡張設定あり
    if (config.extraRecast) {
      if (!config.extraRecast(list, event, abilitiesData, config)) {
        // falseが来る場合は後続処理をしない
        return;
      }
    }

    // アクション設定のリキャストが6未満は対象外とする
    if (config.recast < 6) {
      return;
    }

    // アビリティに該当するBandsDataを取得する
    let item = list.find((b) => b.gameID === abilityGameID);
    if (!item) {
      // なければ新規追加
      const master = abilitiesData.find((m) => m.gameID === abilityGameID);
      item = <BandsData>{
        gameID: abilityGameID,
        name: master?.name || '',
        icon: master?.icon || '',
        bands: [],
      };
      list.push(item);
    }

    // イベントタイプに応じてBandDataを編集
    if ((event.type === 'begincast' && !event?.canceled === false) || event.type === 'cast') {
      // 存在チェック
      const exists = item.bands.find((e) => e.startTime <= event.timestamp
        && event.timestamp <= e.endTime);
      if (exists) {
        // チャージでBandを３分割
        // 既存と被ってる部分
        item.bands.push({
          startTime: event.timestamp,
          endTime: exists.endTime,
          charge: exists.charge ? exists.charge - 1 : undefined,
        });
        // 実行時間から既存の被ってる部分以外のリキャストタイム
        item.bands.push({
          startTime: exists.endTime,
          // endTime: event.timestamp + (config.recast * 1000),
          endTime: exists.endTime + (config.recast * 1000),
          charge: exists.charge,
        });
        // 既存部分
        exists.endTime = event.timestamp;
      } else {
        // Bandを追加
        item.bands.push({
          startTime: event.timestamp,
          endTime: event.timestamp + (config.recast * 1000),
          charge: config.maxcharge ? config.maxcharge - 1 : undefined,
        });
      }
    }
  });

  // バンドデータの終端を整理
  list.forEach((buff) => {
    buff.bands.forEach((b) => {
      const band = b;
      if (band.endTime > fightsData.endTime) {
        // はみ出し部分を切る
        band.endTime = fightsData.endTime;
      }
      // 開始時間からのoffset時間で更新
      band.startTime -= fightsData.startTime;
      band.endTime -= fightsData.startTime;
    });
  });
  list.sort((a, b) => (a.gameID > b.gameID ? -1 : 1));
  return list;
};

/**
 * リキャスト解析、バンドデータ作成
 */
export default class RecastsBands {
  /**
   * 各情報からリキャスト情報のバンドデータを作成する.
   * イベント配列の内容でアクションのリキャストが6以上の場合を対象とする
   * @param report レポートデータ
   * @param castsEvents イベント配列
   * @param actorData アクター
   * @returns
   */
  public static parse(
    report: Report,
    castsEvents: Array<ReportEvent>,
    actorData: ReportActor,
  ) : BandsData[] {
    // 戦闘で使用したアビリティ
    const { abilities } = report.masterData;
    // 戦闘情報、主に開始時間と終了時間
    const fightData = report.fights[0];
    // ジョブの設定情報
    const configs = getJobActionConfig(actorData.subType);

    return createRecastBandsData(castsEvents, abilities, fightData, configs);
  }

  public static create(
    reportData: any,
  ) {
    const {
      abilities, fightData, castsEvents, actorData,
    } = reportData;
    const configs = getJobActionConfig(actorData.subType);
    return createRecastBandsData(castsEvents, abilities, fightData, configs);
  }
}
