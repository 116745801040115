import {
  Report, ReportActor, ReportEvent, ReportFight,
} from '@/services/report';
import { format } from 'date-fns';

const mudra = {
  id: 2260,
  icon: '002000-002904.png',
  name: '忍術',
  recast: 20,
  maxcharge: 2,
};
/**
 * 忍術のリキャスト管理、印３つのどれかで始動でリキャスト発生なので
 * 忍術アビリティのりキャストに集約する
 * @param list Bandデータ
 * @param event イベント
 * @param abilities マスタ
 * @param config 設定
 * @returns 処理継続するかどうか
 */
const recastMudra = (list: any, event: any, abilities: any, config: any) => {
  let item = list
    .find((b: any) => (b.gameID === mudra.id));
  if (!item) {
    // なければ新規追加
    // const master = abilities.find((m: any) => m.gameID === event.abilityGameID);
    item = {
      gameID: mudra.id,
      name: mudra.name,
      icon: mudra.icon,
      bands: [],
    };
    list.push(item);
  }
  // イベントタイプに応じてBandDataを編集
  if ((event.type === 'begincast' && !event?.canceled === false) || event.type === 'cast') {
    // 存在チェック
    const exists = item.bands.find((e: any) => e.startTime <= event.timestamp
      && event.timestamp <= e.endTime);
    if (exists) {
      // チャージでBandを３分割
      // 既存と被ってる部分
      item.bands.push({
        startTime: event.timestamp,
        endTime: exists.endTime,
        charge: exists.charge ? exists.charge - 1 : undefined,
      });
      // 実行時間から既存の被ってる部分以外のリキャストタイム
      item.bands.push({
        startTime: exists.endTime,
        endTime: exists.endTime + (mudra.recast * 1000),
        charge: exists.charge,
      });
      // 既存部分
      exists.endTime = event.timestamp;
    } else {
      // Bandを追加
      item.bands.push({
        startTime: event.timestamp,
        endTime: event.timestamp + (mudra.recast * 1000),
        charge: config.maxcharge ? mudra.maxcharge - 1 : undefined,
      });
    }
  }
  // 後続処理をしない
  return false;
};

/**
 * 縮地のリキャスト管理
 * ２印の忍術を使った場合リキャストをリセットする
 * @param list Bandデータ
 * @param event イベント
 * @param abilities マスタ
 * @param config 設定
 * @returns 処理継続するかどうか
 */
const resetShukuchi = (list: any, event: any, abilities: any, config: any) => {
  const item = list.find((b: any) => (b.gameID === 2262));
  if (!item) {
    return true;
  }
  if ((event.type === 'begincast' && !event?.canceled === false) || event.type === 'cast') {
  // 存在チェック
    const exists = item.bands.find((e: any) => e.startTime <= event.timestamp
    && event.timestamp <= e.endTime);
    if (exists) {
      if (exists.charge > 1) {
        // チャージでBandを３分割
        // 既存と被ってる部分
        item.bands.push({
          startTime: event.timestamp,
          endTime: exists.endTime,
          charge: exists.charge ? exists.charge - 1 : undefined,
        });
      }
      // 既存部分
      exists.endTime = event.timestamp;
    }
  }

  return true;
};

/**
 * 開幕の仕込み水遁を考慮
 * @param list
 * @param event
 * @param abilities
 * @param config
 * @returns
 */
const startNinjutu = (list: any, event: any, abilities: any, config: any) => {
  if (list.length === 0) {
    const item = {
      gameID: mudra.id,
      name: mudra.name,
      icon: mudra.icon,
      bands: [{
        startTime: event.timestamp,
        endTime: event.timestamp + (13.9 * 1000),
        charge: mudra.maxcharge - 1,
      }],
    };
    list.push(item);
  }

  return true;
};

export default [
  // 天の印
  {
    id: 2259,
    extraRecast: recastMudra,
  },
  // 地の印
  {
    id: 2261,
    extraRecast: recastMudra,
  },
  // 人の印
  {
    id: 2263,
    extraRecast: recastMudra,
  },
  // 火遁の術
  {
    id: 2266,
    extraRecast: resetShukuchi,
  },
  // 雷遁の術
  {
    id: 2267,
    extraRecast: resetShukuchi,
  },
  // 氷遁の術
  {
    id: 2268,
    extraRecast: resetShukuchi,
  },
  // 火遁
  {
    id: 18876,
    extraRecast: resetShukuchi,
  },
  // 雷遁の術
  {
    id: 18877,
    extraRecast: resetShukuchi,
  },
  // 氷遁の術
  {
    id: 18878,
    extraRecast: resetShukuchi,
  },
  // 水遁の術
  {
    id: 2271,
    extraRecast: startNinjutu,
  },
];

type GraphItem = {
  startTime: number
  endTime: number
  from: number
  to: number
}

const createResourceNinki = (events: ReportEvent[], fight: ReportFight) => {
  const fightEnd = fight.endTime;
  const values: GraphItem[] = [];
  const overs: GraphItem[] = [];
  const state = {
    ninki: 0,
    bunshin: 0,
  };

  let pre: GraphItem| null = null;
  let preo: GraphItem| null = null;
  events.forEach((event) => {
    let resource = 0;
    if (event.type !== 'cast') {
      return;
    }
    if (preo) {
      preo.endTime = event.timestamp;
      preo = null;
    }
    if (event.abilityGameID === 2240 || event.abilityGameID === 2242
        || event.abilityGameID === 2247 || event.abilityGameID === 2254
        || event.abilityGameID === 16488 || event.abilityGameID === 25876
        || event.abilityGameID === 25778 || event.abilityGameID === 25777) {
      // 双刃旋、風断ち、投刃、血花五月雨、八卦無刃殺、月影雷獣爪、月影雷獣牙、風来刃
      resource += 5;
      if (state.bunshin > 0) {
        resource += 5;
        state.bunshin -= 1;
      }
    }
    if (event.abilityGameID === 25774) {
    // 残影鎌鼬
      resource += 10;
      if (state.bunshin > 0) {
        resource += 5;
        state.bunshin -= 1;
      }
    }
    if (event.abilityGameID === 2255 || event.abilityGameID === 3563) {
      // 旋風刃、強甲破点突
      resource += 15;
      if (state.bunshin > 0) {
        resource += 5;
        state.bunshin -= 1;
      }
    }
    if (event.abilityGameID === 2248 || event.abilityGameID === 36957) {
      // ぶんどる、毒盛の術
      resource += 40;
    }
    if (event.abilityGameID === 7401 || event.abilityGameID === 7402
        || event.abilityGameID === 36959 || event.abilityGameID === 36960) {
      // 口寄せの術・大蝦蟇、六道輪廻、口寄せの術・蝦蟇仙、是生滅法
      resource -= 50;
    }
    if (event.abilityGameID === 16493) {
      // 分身の術
      state.bunshin = 5;
      resource -= 50;
    }
    if (event.abilityGameID === 16489) {
      resource += 50;
    }
    const total = state.ninki + resource;

    if (total > 100) {
      const over = {
        startTime: event.timestamp,
        endTime: fightEnd,
        from: 100,
        to: total - 100,
      };
      overs.push(over);
      resource = 100 - state.ninki; // 実際に変動した値を計算
      preo = over;
    }
    // 変動している場合は更新
    if (resource !== 0) {
      state.ninki += resource;
      const value = {
        startTime: pre ? pre.endTime : 0,
        endTime: event.timestamp,
        from: pre ? pre.to : 0,
        to: state.ninki,
      };
      // if (pre) {
      //   pre.endTime = event.timestamp;
      // }
      values.push(value);
      pre = value;
    }
  });

  // 最後
  const last = values[values.length - 1];
  if (last !== undefined) {
    values.push({
      startTime: last.endTime,
      endTime: fightEnd,
      from: last.to,
      to: 0,
    });
  }

  values.forEach((v) => {
    const value = v;
    // 開始時間からのoffset時間で更新
    value.startTime -= fight.startTime;
    value.endTime -= fight.startTime;
  });
  overs.forEach((v) => {
    const value = v;
    // 開始時間からのoffset時間で更新
    value.startTime -= fight.startTime;
    value.endTime -= fight.startTime;
  });

  const resourceItem = {
    id: 'ninki',
    name: '忍気',
    min: 0,
    max: 100,
    type: 'stepped',
    values,
    overs,
  };
  return resourceItem;
};

const calcEndTime = (startTime: number, huton: number) => startTime + huton * 1000;
const calcFuton = (startTime: number, endTime: number) => Math.round((endTime - startTime) / 1000);
// TODO やりかけ
const createResourceHuton = (events: ReportEvent[], fight: ReportFight) => {
  const fightStart = fight.startTime;
  const fightEnd = fight.endTime;
  const values: GraphItem[] = [];
  const overs: GraphItem[] = [];

  const state = {
    huton: 53,
  };
  console.log('createResourceHuton');
  // 最初のゲージ量を計算
  let pre: GraphItem = {
    startTime: fightStart,
    endTime: calcEndTime(fightStart, state.huton),
    from: state.huton,
    to: 0,
  };
  values.push(pre);

  events.forEach((event) => {
    let resource = 0;
    if (event.type !== 'cast') {
      return;
    }
    if (event.abilityGameID === 3563) {
      console.log(
        'haten!',
        event.timestamp,
        pre.endTime,
        pre.startTime,
        format(new Date(event.timestamp - fightStart), 'mm:ss.S'),
      );
    }
    if (event.abilityGameID === 3563 && event.timestamp <= pre.endTime) {
      // 強甲破点突 & 風遁が持続している
      resource = 30;
    } else if (event.abilityGameID === 2269 || event.abilityGameID === 25876
       || event.abilityGameID === 18879) {
      // 風来刃、風遁の術
      resource = 60;
    } else if ((event.abilityGameID === 25774 || event.abilityGameID === 16488) && event.timestamp <= pre.endTime) {
      // 残影鎌鼬、 八卦無刃殺
      resource = 10;
    } else {
      // 上記以外は処理しない
      return;
    }

    if (pre.endTime > event.timestamp) {
      // 風遁持続している場合、経過時間で消費量を計算して更新
      pre.endTime = event.timestamp;
      pre.to = pre.from - calcFuton(pre.startTime, event.timestamp);
    } else {
      console.log('huton zero');
      // 前回の風遁が持続していない場合は間に0状態を挿入
      const zero: GraphItem = {
        startTime: pre.endTime,
        endTime: event.timestamp,
        from: 0,
        to: 0,
      };
      values.push(zero);
      pre = zero;
    }

    const total = pre.to + resource;
    console.log('total', total);
    if (total > 60) {
      console.log('huton over');
      // 上昇量加算して上限をオーバー
      const over = {
        startTime: event.timestamp,
        endTime: event.timestamp + 1000,
        from: 60,
        to: total - 60,
      };
      overs.push(over);
      // 上限の中での実際の増減量を計算
      resource = 60 - pre.to;
      console.log('resource', resource);
    }
    // 変動している場合は更新
    if (resource !== 0) {
      const value = {
        startTime: event.timestamp,
        endTime: calcEndTime(event.timestamp, pre.to + resource),
        from: pre.to + resource,
        to: 0,
      };
      console.log('update huton!', pre, value);
      values.push(value);
      pre = value;
    }
  });

  // 最後
  const last = values[values.length - 1];
  last.endTime = fightEnd;
  last.to = pre.from - calcFuton(pre.startTime, fightEnd);
  values.push({
    startTime: fightEnd,
    endTime: fightEnd,
    from: last.to,
    to: 0,
  });

  values.forEach((v) => {
    const value = v;
    // 開始時間からのoffset時間で更新
    value.startTime -= fight.startTime;
    value.endTime -= fight.startTime;
  });
  overs.forEach((v) => {
    const value = v;
    // 開始時間からのoffset時間で更新
    value.startTime -= fight.startTime;
    value.endTime -= fight.startTime;
  });

  const resourceItem = {
    id: 'huton',
    type: 'linear',
    values,
    overs,
  };

  return resourceItem;
};

const createResourceKazematoi = (events: ReportEvent[], fight: ReportFight) => {
  const fightEnd = fight.endTime;
  const values: GraphItem[] = [];
  const overs: GraphItem[] = [];
  const state = {
    kazematoi: 0,
  };

  let pre: GraphItem| null = null;
  let preo: GraphItem| null = null;
  events.forEach((event) => {
    let resource = 0;
    if (event.type !== 'cast') {
      return;
    }
    if (preo) {
      preo.endTime = event.timestamp;
      preo = null;
    }

    if (event.abilityGameID === 2255) {
      // 旋風刃
      if (state.kazematoi > 0) {
        resource -= 20;
      }
    }
    if (event.abilityGameID === 3563) {
      // 強甲破点突
      resource += 40;
    }

    const total = state.kazematoi + resource;
    if (total > 100) {
      const over = {
        startTime: event.timestamp,
        endTime: fightEnd,
        from: 100,
        to: total - 100,
      };
      overs.push(over);
      resource = 100 - state.kazematoi; // 実際に変動した値を計算
      preo = over;
    }
    // 変動している場合は更新
    if (resource !== 0) {
      state.kazematoi += resource;
      const value = {
        startTime: pre ? pre.endTime : 0,
        endTime: event.timestamp,
        from: pre ? pre.to : 0,
        to: state.kazematoi,
      };
      // if (pre) {
      //   pre.endTime = event.timestamp;
      // }
      values.push(value);
      pre = value;
    }
  });

  // 最後
  const last = values[values.length - 1];
  if (last !== undefined) {
    values.push({
      startTime: last.endTime,
      endTime: fightEnd,
      from: last.to,
      to: 0,
    });
  }

  values.forEach((v) => {
    const value = v;
    // 開始時間からのoffset時間で更新
    value.startTime -= fight.startTime;
    value.endTime -= fight.startTime;
  });
  overs.forEach((v) => {
    const value = v;
    // 開始時間からのoffset時間で更新
    value.startTime -= fight.startTime;
    value.endTime -= fight.startTime;
  });

  const resourceItem = {
    id: 'ninki',
    name: '風纏',
    type: 'stepped',
    min: 0,
    max: 5,
    values,
    overs,
  };
  return resourceItem;
};

export const createResourceGraphData = (
  fight: ReportFight,
  events: Array<ReportEvent>,
  actor: ReportActor,
) => {
  // 忍気
  const reource1 = createResourceNinki(events, fight);
  const resource2 = createResourceKazematoi(events, fight);
  return [reource1, resource2];
};
