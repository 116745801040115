import {
  FightData, ActorData, ReportData,
} from '@/types/report.d';
import { ReportService } from '@/services/report';
import { nanoid } from 'nanoid';
import ReportSettings from '@/logic/ReportSettings';
import CastsTimes from '@/logic/CastsTimes';
import EnemiesTimes from '@/logic/EnemiesTimes';
import BuffsBands from '@/logic/BuffsBands';
import DebuffsBands from '@/logic/DebuffsBands';
import RecastsBands from '@/logic/RecastsBands';
import ResourcesGraph from '@/logic/ResourcesGraph';

/**
 * イベントからバフ等の状態イベントを作る
 * @param defaultStatus キャラクタの初期状態
 * @param actions アクション設定
 * @param events イベント配列
 * @param abilities アビリティ配列（マスタ）
 * @returns void
 */
// export const evaluateEvents = (
//   defaultStatus: ActorStatusData,
//   actions: Array<ActionItemConfig>,
//   events: Array<EventData>,
//   abilities: Array<AbilityData>,
// ): Array<AbilityStatusData> => {
//   // 各バフ・リキャストの状態を作成
//   const statuses: Array<AbilityStatusData> = [];
//   actions.filter((action) => action.watch).forEach((action) => {
//     const ability: AbilityData | undefined = abilities.find((m) => m.gameID === action.gameID);
//     if (ability === undefined) {
//       console.warn(`not found ablity ${action.gameID}`);
//       return;
//     }
//     statuses.push(<AbilityStatusData> {
//       gameID: action.gameID,
//       name: ability.name,
//       type: action.type,
//       icon: ability.icon,
//       recast: action.recast ? action.recast * 1000 : 0,
//       effect: action.effect ? action.effect * 1000 : 0,
//       charge: action.charge ? action.charge : 0,
//       events: [],
//     });
//   });

//   // キャラクタの状態
//   const state = <ActorStatusData>deepClone(defaultStatus);

//   // イベント毎に状態評価
//   events.forEach((event) => {
//     const action = actions.find((a) => a.gameID === event.abilityGameID);

//     if (action) {
//       if (action.evaluate) {
//         action.evaluate(statuses, event, state);
//       } else if (action.autoEffect) {
//         if (typeof action.autoEffect === 'number') {
//           effectOn(action.autoEffect, statuses, event);
//         } else {
//           effectOn(action.gameID, statuses, event);
//         }
//       }
//     } else {
//       // console.warn('no action config', event.abilityGameID, event.name);
//     }
//   });
//   return statuses;
// };

/**
 * レポートの追加
 * @param code
 * @param fightId
 * @param actorId
 */
const add = async (
  code: string,
  fightId: number,
  actorId: number,
) => {
  // レポート情報収集
  const report = await ReportService.get(code, fightId);

  // 戦闘情報
  const fight: FightData = report.fights[0];

  // マスタ類
  const { abilities, actors } = report.masterData;

  // ランク情報
  const rankings = report.rankings.data[0];

  // Actor
  const actor: ActorData | undefined = actors.find((a) => a.id === actorId);
  if (actor === undefined) {
    throw new Error(`actor not found at id:${actorId}`);
  }

  const combatantInfo = await ReportService.conbatantInfo(code, fight.startTime, fight.endTime, actorId);

  // Actorイベント情報取得
  const castsEvents = await ReportService.actorEvents(code, fight.startTime, fight.endTime, actorId, 'Casts');

  const buffEvents = await ReportService.actorEvents(code, fight.startTime, fight.endTime, actorId, 'Buffs');

  // NPCイベント情報取得
  const enemiesEvents = await ReportService.enemiesEvents(code, fight.startTime, fight.endTime, 'Casts');

  const debuffEvents = await ReportService.enemiesEvents(code, fight.startTime, fight.endTime, 'Debuffs');

  // ランク情報
  Object.values(rankings.roles).forEach((role) => {
    const charactor = role.characters
      .find((c) => (c.name === actor.name && (actor.server === null || c?.server?.name === actor.server)));
    if (charactor) {
      actor.rank = charactor.rank;
      actor.rankPercent = charactor.rankPercent;
      actor.amount = charactor.amount;
    }
  });
  console.time('calculate');
  const casts = CastsTimes.parse(report, castsEvents, actor);
  const enemies = EnemiesTimes.parse(report, enemiesEvents);
  const buffs = BuffsBands.parse(report, buffEvents);
  const debuffs = DebuffsBands.parse(report, debuffEvents);
  const recasts = RecastsBands.parse(report, castsEvents, actor);
  const resources = ResourcesGraph.parse(fight, castsEvents, actor);
  console.timeEnd('calculate');

  // // ActorのジョブのAction設定情報取得
  // console.log(actor.subType);
  // const config = getActionConfig(actor.subType);
  // const statuses = evaluateEvents(config.defaultStatus, config.actions, casts, abilities);

  // 設定情報
  const settings = ReportSettings.create();
  // settings.buffs.abilities = ReportSettings.createAbilitySettings(buffs);
  // settings.debuffs.abilities = ReportSettings.createAbilitySettings(debuffs);
  // settings.recasts.abilities = ReportSettings.createAbilitySettings(recasts);

  // 結果設定
  const reportData = <ReportData>{
    id: nanoid(),
    code,
    actorData: actor,
    fightData: fight,
    actors,
    abilities,
    combatantInfo,
    castsEvents,
    buffEvents,
    enemiesEvents,
    debuffEvents,
    casts,
    recasts,
    enemies,
    buffs,
    debuffs,
    resources,
    settings,
  };

  return reportData;
};

export default {
  add,
};
