import AstrologianBuffs from '@/assets/config/buffs/Astrologian.json';
import BardBuffs from '@/assets/config/buffs/Bard.json';
import BlackMageBuffs from '@/assets/config/buffs/BlackMage.json';
import DarkKnightBuffs from '@/assets/config/buffs/DarkKnight.json';
import DragoonBuffs from '@/assets/config/buffs/Dragoon.json';
import MachinistBuffs from '@/assets/config/buffs/Machinist.json';
import MonkBuffs from '@/assets/config/buffs/Monk.json';
import NinjaBuffs from '@/assets/config/buffs/Ninja.json';
import PaladinBuffs from '@/assets/config/buffs/Paladin.json';
import ScholarBuffs from '@/assets/config/buffs/Scholar.json';
import SummonerBuffs from '@/assets/config/buffs/Summoner.json';
import WarriorBuffs from '@/assets/config/buffs/Warrior.json';
import WhiteMageBuffs from '@/assets/config/buffs/WhiteMage.json';
import RedMageBuffs from '@/assets/config/buffs/RedMage.json';
import SamuraiBuffs from '@/assets/config/buffs/Samurai.json';
import DancerBuffs from '@/assets/config/buffs/Dancer.json';
import GunbreakerBuffs from '@/assets/config/buffs/Gunbreaker.json';
import ReaperBuffs from '@/assets/config/buffs/Reaper.json';
import SageBuffs from '@/assets/config/buffs/Sage.json';
import ViperBuffs from '@/assets/config/buffs/Viper.json';
import PictomancerBuffs from '@/assets/config/buffs/Pictomancer.json';
import {
  SPEC_TYPE, StatusConfig, JobBuffConfig,
} from './types';

export const getAllBuffConfig = ():StatusConfig[] => [
  ...<StatusConfig[]>AstrologianBuffs,
  ...<StatusConfig[]>BardBuffs,
  ...<StatusConfig[]>BlackMageBuffs,
  ...<StatusConfig[]>DarkKnightBuffs,
  ...<StatusConfig[]>DragoonBuffs,
  ...<StatusConfig[]>MachinistBuffs,
  ...<StatusConfig[]>MonkBuffs,
  ...<StatusConfig[]>NinjaBuffs,
  ...<StatusConfig[]>PaladinBuffs,
  ...<StatusConfig[]>ScholarBuffs,
  ...<StatusConfig[]>SummonerBuffs,
  ...<StatusConfig[]>WarriorBuffs,
  ...<StatusConfig[]>WhiteMageBuffs,
  ...<StatusConfig[]>RedMageBuffs,
  ...<StatusConfig[]>SamuraiBuffs,
  ...<StatusConfig[]>DancerBuffs,
  ...<StatusConfig[]>GunbreakerBuffs,
  ...<StatusConfig[]>ReaperBuffs,
  ...<StatusConfig[]>SageBuffs,
  ...<StatusConfig[]>ViperBuffs,
  ...<StatusConfig[]>PictomancerBuffs,
];

export const getJobBuffConfig = (specType: string):StatusConfig[] => {
  if (specType === 'Astrologian') {
    return <StatusConfig[]>AstrologianBuffs;
  } if (specType === 'Bard') {
    return <StatusConfig[]>BardBuffs;
  } if (specType === 'BlackMage') {
    return <StatusConfig[]>BlackMageBuffs;
  } if (specType === 'DarkKnight') {
    return <StatusConfig[]>DarkKnightBuffs;
  } if (specType === 'Dragoon') {
    return <StatusConfig[]>DragoonBuffs;
  } if (specType === 'Machinist') {
    return <StatusConfig[]>MachinistBuffs;
  } if (specType === 'Monk') {
    return <StatusConfig[]>MonkBuffs;
  } if (specType === 'Ninja') {
    return <StatusConfig[]>NinjaBuffs;
  } if (specType === 'Paladin') {
    return <StatusConfig[]>PaladinBuffs;
  } if (specType === 'Scholar') {
    return <StatusConfig[]>ScholarBuffs;
  } if (specType === 'Summoner') {
    return <StatusConfig[]>SummonerBuffs;
  } if (specType === 'Warrior') {
    return <StatusConfig[]>WarriorBuffs;
  } if (specType === 'WhiteMage') {
    return <StatusConfig[]>WhiteMageBuffs;
  } if (specType === 'RedMage') {
    return <StatusConfig[]>RedMageBuffs;
  } if (specType === 'Samurai') {
    return <StatusConfig[]>SamuraiBuffs;
  } if (specType === 'Dancer') {
    return <StatusConfig[]>DancerBuffs;
  } if (specType === 'Gunbreaker') {
    return <StatusConfig[]>GunbreakerBuffs;
  } if (specType === 'Reaper') {
    return <StatusConfig[]>ReaperBuffs;
  } if (specType === 'Sage') {
    return <StatusConfig[]>SageBuffs;
  } if (specType === 'Reaper') {
    return <StatusConfig[]>ReaperBuffs;
  } if (specType === 'Sage') {
    return <StatusConfig[]>SageBuffs;
  } if (specType === 'Viper') {
    return <StatusConfig[]>ViperBuffs;
  } if (specType === 'Pictomancer') {
    return <StatusConfig[]>PictomancerBuffs;
  }
  return [];
};

export const getAllJobBuffConfig = ():JobBuffConfig[] => {
  const allConfigs:JobBuffConfig[] = [];
  SPEC_TYPE.forEach((specType, index) => {
    allConfigs.push({
      id: index,
      icon: specType,
      name: specType,
      abilities: getJobBuffConfig(specType),
    });
  });
  return allConfigs;
};

export default {
  getAllBuffConfig,
  getJobBuffConfig,
  getAllJobBuffConfig,
};
