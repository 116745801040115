import AstrologianActions from '@/assets/config/actions/Astrologian.json';
import BardActions from '@/assets/config/actions/Bard.json';
import BlackMageActions from '@/assets/config/actions/BlackMage.json';
import DarkKnightActions from '@/assets/config/actions/DarkKnight.json';
import DragoonActions from '@/assets/config/actions/Dragoon.json';
import MachinistActions from '@/assets/config/actions/Machinist.json';
import MonkActions from '@/assets/config/actions/Monk.json';
import NinjaActions from '@/assets/config/actions/Ninja.json';
import PaladinActions from '@/assets/config/actions/Paladin.json';
import ScholarActions from '@/assets/config/actions/Scholar.json';
import SummonerActions from '@/assets/config/actions/Summoner.json';
import WarriorActions from '@/assets/config/actions/Warrior.json';
import WhiteMageActions from '@/assets/config/actions/WhiteMage.json';
import RedMageActions from '@/assets/config/actions/RedMage.json';
import SamuraiActions from '@/assets/config/actions/Samurai.json';
import DancerActions from '@/assets/config/actions/Dancer.json';
import GunbreakerActions from '@/assets/config/actions/Gunbreaker.json';
import ReaperActions from '@/assets/config/actions/Reaper.json';
import SageActions from '@/assets/config/actions/Sage.json';
import Viperctions from '@/assets/config/actions/Viper.json';
import PictomancerActions from '@/assets/config/actions/Pictomancer.json';

import NinjaExtras from './extras/Ninja';
import {
  SPEC_TYPE, ActionConfig, JobActionConfig,
} from './types';

const margeConfig = (actions: any[], extras: any[]) => {
  actions.forEach((action) => {
    const extra = extras.find((e) => e.id === action.id);
    if (extra) {
      Object.assign(action, extra);
    }
  });
};

margeConfig(NinjaActions, NinjaExtras);

export const getAllActionConfig = ():ActionConfig[] => [
  ...<ActionConfig[]>AstrologianActions,
  ...<ActionConfig[]>BardActions,
  ...<ActionConfig[]>BlackMageActions,
  ...<ActionConfig[]>DarkKnightActions,
  ...<ActionConfig[]>DragoonActions,
  ...<ActionConfig[]>MachinistActions,
  ...<ActionConfig[]>MonkActions,
  ...<ActionConfig[]>NinjaActions,
  ...<ActionConfig[]>PaladinActions,
  ...<ActionConfig[]>ScholarActions,
  ...<ActionConfig[]>SummonerActions,
  ...<ActionConfig[]>WarriorActions,
  ...<ActionConfig[]>WhiteMageActions,
  ...<ActionConfig[]>RedMageActions,
  ...<ActionConfig[]>SamuraiActions,
  ...<ActionConfig[]>DancerActions,
  ...<ActionConfig[]>GunbreakerActions,
  ...<ActionConfig[]>ReaperActions,
  ...<ActionConfig[]>SageActions,
  ...<ActionConfig[]>Viperctions,
  ...<ActionConfig[]>PictomancerActions,
];

export const getJobActionConfig = (specType: string):ActionConfig[] => {
  if (specType === 'Astrologian') {
    return <ActionConfig[]>AstrologianActions;
  } if (specType === 'Bard') {
    return <ActionConfig[]>BardActions;
  } if (specType === 'BlackMage') {
    return <ActionConfig[]>BlackMageActions;
  } if (specType === 'DarkKnight') {
    return <ActionConfig[]>DarkKnightActions;
  } if (specType === 'Dragoon') {
    return <ActionConfig[]>DragoonActions;
  } if (specType === 'Machinist') {
    return <ActionConfig[]>MachinistActions;
  } if (specType === 'Monk') {
    return <ActionConfig[]>MonkActions;
  } if (specType === 'Ninja') {
    return <ActionConfig[]>NinjaActions;
  } if (specType === 'Paladin') {
    return <ActionConfig[]>PaladinActions;
  } if (specType === 'Scholar') {
    return <ActionConfig[]>ScholarActions;
  } if (specType === 'Summoner') {
    return <ActionConfig[]>SummonerActions;
  } if (specType === 'Warrior') {
    return <ActionConfig[]>WarriorActions;
  } if (specType === 'WhiteMage') {
    return <ActionConfig[]>WhiteMageActions;
  } if (specType === 'RedMage') {
    return <ActionConfig[]>RedMageActions;
  } if (specType === 'Samurai') {
    return <ActionConfig[]>SamuraiActions;
  } if (specType === 'Dancer') {
    return <ActionConfig[]>DancerActions;
  } if (specType === 'Gunbreaker') {
    return <ActionConfig[]>GunbreakerActions;
  } if (specType === 'Reaper') {
    return <ActionConfig[]>ReaperActions;
  } if (specType === 'Sage') {
    return <ActionConfig[]>SageActions;
  } if (specType === 'Viper') {
    return <ActionConfig[]>Viperctions;
  } if (specType === 'Pictomancer') {
    return <ActionConfig[]>PictomancerActions;
  }
  return [];
};

export const getAllJobActionConfig = ():JobActionConfig[] => {
  const allConfigs:JobActionConfig[] = [];
  SPEC_TYPE.forEach((specType, index) => {
    allConfigs.push({
      id: index,
      icon: specType,
      name: specType,
      abilities: getJobActionConfig(specType),
    });
  });
  return allConfigs;
};

export default {
  getAllActionConfig,
  getJobActionConfig,
  getAllJobActionConfig,
};
