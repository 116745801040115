export const SPEC_TYPE = <const>[
  'Astrologian',
  'Bard',
  'BlackMage',
  'DarkKnight',
  'Dragoon',
  'Machinist',
  'Monk',
  'Ninja',
  'Paladin',
  'Scholar',
  'Summoner',
  'Warrior',
  'WhiteMage',
  'RedMage',
  'Samurai',
  'Dancer',
  'Gunbreaker',
  'Reaper',
  'Sage',
  'Viper',
  'Pictomancer',
];
export type SpecType = typeof SPEC_TYPE[number];

export type ExtraRecast = {
  (bands: any[], event: any, abilities: any[], config: any): boolean;
}

export type ActionConfig = {
  id: number
  icon: string
  name: string
  category: string
  description: string
  gcd: boolean
  cast: number
  recast: number
  duration?: number
  maxcharge?: number
  maxstack?: number
  display: boolean
  extraRecast?: ExtraRecast
}

export type StatusConfig = {
  id: number
  icon: string
  name: string
  display: boolean
}

export type JobActionConfig = {
  id: number
  icon: string
  name: SpecType
  abilities: ActionConfig[]
}

export type JobBuffConfig = {
  id: number
  icon: string
  name: SpecType
  abilities: StatusConfig[]
}

export type JobDebuffConfig = {
  id: number
  icon: string
  name: SpecType
  abilities: StatusConfig[]
}

export type ExtraConfig = {
  id: number
  extraRecast?: ExtraRecast
}
