<template>
  <el-card
    :body-style="{ padding: '0px', height: 'calc( 100% - 80px )' }"
    shadow="never"
    class="report__content"
  >
    <template #header>
      <ReportHeader @remove="remove" />
    </template>
    <div class="report__scroll" ref="reportBody">
      <div class="report__graphpaper1">
      <div class="report__graphpaper2">
      <div class="report__graphpaper3">
        <div class="report__body">
          <ReportTimecale v-show="settings.timescale" class="report__item" />
          <ReportEnemiesTimeLine v-show="settings.enemies.display" class="report__item" />
          <ReportDebuffsTimeLine v-show="settings.debuffs.display" class="report__item" />
          <ReportCastsTimeLine v-show="settings.casts.display" class="report__item" />
          <ReportResourcesTimeLine v-show="settings.resources.display" class="report__item" />
          <ReportRecastsTimeLine v-show="settings.recasts.display" class="report__item" />
          <ReportBuffsTimeLine v-show="settings.buffs.display" class="report__item" />
        </div>
      </div>
      </div>
      </div>
    </div>
  </el-card>
</template>

<script setup>
import {
  ref, inject, provide, watch, onMounted, computed,
} from 'vue';
import useProject from '@/store/projects';
import ReportHeader from './ReportHeader.vue';
import ReportCastsTimeLine from './ReportCastsTimeLine.vue';
import ReportRecastsTimeLine from './ReportRecastsTimeLine.vue';
import ReportEnemiesTimeLine from './ReportEnemiesTimeLine.vue';
import ReportDebuffsTimeLine from './ReportDebuffsTimeLine.vue';
import ReportBuffsTimeLine from './ReportBuffsTimeLine.vue';

import ReportResourcesTimeLine from './ReportResourcesTimeLine.vue';
import ReportTimecale from './ReportTimecale.vue';

const storeProject = useProject();

const props = defineProps({
  'report-data': {
    type: Object,
    default: () => ({}),
  },
});
const emit = defineEmits(['update-settings', 'remove']);

const settings = computed(() => {
  if (props.reportData.settings.inherits) {
    return storeProject.settings;
  }
  return props.reportData.settings;
});

provide('settings-data', props.reportData.settings);

provide('use-props', () => props);

/**
 * スクロールシンク
 */
const reportBody = ref(null);
const syncScroll = inject('sync-scroll');

watch(syncScroll, (val) => {
  if (val.sync) {
    reportBody.value.scrollTop = val.scroll;
  }
}, { deep: true });

onMounted(() => {
  reportBody.value.addEventListener('scroll', () => {
    if (syncScroll.sync) {
      syncScroll.scroll = reportBody.value.scrollTop;
    }
  });
});

const remove = () => {
  emit('remove', props.reportData);
};

</script>

<style scoped>
.report__scroll {
  height: 100%;
  overflow: auto;
}
.report__graphpaper1 {
  display: table;
  /* graph paper */
  background-image: linear-gradient(0deg, transparent calc(100% - 1px), #f0f0f0 calc(100% - 1px)),
                    linear-gradient(90deg, transparent calc(100% - 1px), #f0f0f0 calc(100% - 1px));
  background-size: 30px 30px;
  background-repeat: repeat;
  background-position: left top;
}
.report__graphpaper2 {
  display: table;
  /* graph paper */
  background-image: linear-gradient(0deg, transparent calc(100% - 1px), #BDBDBD calc(100% - 1px));
  background-size: 30px 150px;
  background-repeat: repeat;
  background-position: left top;
}
.report__graphpaper3 {
  display: table;
  /* graph paper */
  background-image: linear-gradient(0deg, transparent calc(100% - 1px), #757575 calc(100% - 1px));
  background-size: 30px 300px;
  background-repeat: repeat;
  background-position: left top;
}
.report__body {
  display: flex;
  justify-content: flex-start;
}
.report__item {

  /*flex-grow: 1;*/
}
/*
.report__content:hover :deep(.report__operation-btn) {
  opacity: 0.8;
}*/
</style>
