<template>
  <AppHeader />
  <el-main>
    <router-view/>
  </el-main>
</template>

<script setup>
import { onMounted } from 'vue';
import AppHeader from '@/components/Layout/AppHeader.vue';
import useMaster from '@/store/master';

const storeMaster = useMaster();

onMounted(async () => {
  try {
    await storeMaster.load();
  } catch (e) {
    console.error(e);
  }
});

</script>

<style scoped>
</style>
