import gql from 'graphql-tag';
import { query } from './client';

/**
 * https://ja.fflogs.com/v2-api-docs/ff/gamespec.doc.html
 */
export interface GameSpec {
  id: number
  name: string
  slug: string
}

export class GameService {
  static async specs(): Promise<GameSpec[]> {
    const result = await query(gql`
      query {
        gameData {
          class (id: 1) {
            specs {
              id
              name
              slug
            }
          }
        }
      }
    `);

    console.log('game.class', result);
    return result.gameData.class.specs;
  }
}
