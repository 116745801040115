import axios from 'axios';
import { print, ASTNode } from 'graphql';

const CLIENT_ID = process.env.VUE_APP_CLIENT_ID;
const CLIENT_SECRET = process.env.VUE_APP_CLIENT_SECRET;

/**
 * トークンキャッシュ
 */
const cache: {
  access_token: string,
  expires_on: number,
} = {
  access_token: '',
  expires_on: 0,
};

/**
 * アクセストークン取得
 * @returns アクセストークン
 */
const getAccessToken = async () => {
  if (new Date().getTime() > cache.expires_on) {
    const payload = {
      grant_type: 'client_credentials',
      client_id: CLIENT_ID,
      client_secret: CLIENT_SECRET,
    };
    const res = await axios.post('https://ja.fflogs.com/oauth/token', payload);

    cache.access_token = res.data.access_token;
    cache.expires_on = res.data.expires_on * 1000;
  }
  return cache.access_token;
};

/**
 * APIインスタンス
 */
const api = axios.create();

/**
 * インターセプタ
 */
api.interceptors.request.use(async (config) => {
  const conf = config;
  const accessToken = await getAccessToken();
  if (conf.headers) {
    conf.headers.Authorization = `Bearer ${accessToken}`;
  }
  return conf;
});

/**
 * FFLOGSのGrapheQLへの問い合わせ
 * @param ast GraphQLのAST(gql Tagged Templateで作成)
 * @returns リクエスト結果
 */
export const query = async (ast: ASTNode) => {
  const res = await api.post('https://ja.fflogs.com/api/v2/client', {
    query: print(ast),
  });

  if (res.data.errors) {
    console.error('GraphQL return errors', res.data.errors);
    throw new Error(res.data.errors);
  }

  return res.data.data;
};

export default {
  query,
};
