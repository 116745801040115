import AstrologianDebuffs from '@/assets/config/debuffs/Astrologian.json';
import BardDebuffs from '@/assets/config/debuffs/Bard.json';
import BlackMageDebuffs from '@/assets/config/debuffs/BlackMage.json';
import DarkKnightDebuffs from '@/assets/config/debuffs/DarkKnight.json';
import DragoonDebuffs from '@/assets/config/debuffs/Dragoon.json';
import MachinistDebuffs from '@/assets/config/debuffs/Machinist.json';
import MonkDebuffs from '@/assets/config/debuffs/Monk.json';
import NinjaDebuffs from '@/assets/config/debuffs/Ninja.json';
import PaladinDebuffs from '@/assets/config/debuffs/Paladin.json';
import ScholarDebuffs from '@/assets/config/debuffs/Scholar.json';
import SummonerDebuffs from '@/assets/config/debuffs/Summoner.json';
import WarriorDebuffs from '@/assets/config/debuffs/Warrior.json';
import WhiteMageDebuffs from '@/assets/config/debuffs/WhiteMage.json';
import RedMageDebuffs from '@/assets/config/debuffs/RedMage.json';
import SamuraiDebuffs from '@/assets/config/debuffs/Samurai.json';
import DancerDebuffs from '@/assets/config/debuffs/Dancer.json';
import GunbreakerDebuffs from '@/assets/config/debuffs/Gunbreaker.json';
import ReaperDebuffs from '@/assets/config/debuffs/Reaper.json';
import SageDebuffs from '@/assets/config/debuffs/Sage.json';
import ViperDebuffs from '@/assets/config/debuffs/Viper.json';
import PictomancerDebuffs from '@/assets/config/debuffs/Pictomancer.json';
import {
  SPEC_TYPE, StatusConfig, JobDebuffConfig,
} from './types';

export const getAllDebuffConfig = ():StatusConfig[] => [
  ...<StatusConfig[]>AstrologianDebuffs,
  ...<StatusConfig[]>BardDebuffs,
  ...<StatusConfig[]>BlackMageDebuffs,
  ...<StatusConfig[]>DarkKnightDebuffs,
  ...<StatusConfig[]>DragoonDebuffs,
  ...<StatusConfig[]>MachinistDebuffs,
  ...<StatusConfig[]>MonkDebuffs,
  ...<StatusConfig[]>NinjaDebuffs,
  ...<StatusConfig[]>PaladinDebuffs,
  ...<StatusConfig[]>ScholarDebuffs,
  ...<StatusConfig[]>SummonerDebuffs,
  ...<StatusConfig[]>WarriorDebuffs,
  ...<StatusConfig[]>WhiteMageDebuffs,
  ...<StatusConfig[]>RedMageDebuffs,
  ...<StatusConfig[]>SamuraiDebuffs,
  ...<StatusConfig[]>DancerDebuffs,
  ...<StatusConfig[]>GunbreakerDebuffs,
  ...<StatusConfig[]>ReaperDebuffs,
  ...<StatusConfig[]>SageDebuffs,
];

export const getJobDebuffConfig = (specType: string):StatusConfig[] => {
  if (specType === 'Astrologian') {
    return <StatusConfig[]>AstrologianDebuffs;
  } if (specType === 'Bard') {
    return <StatusConfig[]>BardDebuffs;
  } if (specType === 'BlackMage') {
    return <StatusConfig[]>BlackMageDebuffs;
  } if (specType === 'DarkKnight') {
    return <StatusConfig[]>DarkKnightDebuffs;
  } if (specType === 'Dragoon') {
    return <StatusConfig[]>DragoonDebuffs;
  } if (specType === 'Machinist') {
    return <StatusConfig[]>MachinistDebuffs;
  } if (specType === 'Monk') {
    return <StatusConfig[]>MonkDebuffs;
  } if (specType === 'Ninja') {
    return <StatusConfig[]>NinjaDebuffs;
  } if (specType === 'Paladin') {
    return <StatusConfig[]>PaladinDebuffs;
  } if (specType === 'Scholar') {
    return <StatusConfig[]>ScholarDebuffs;
  } if (specType === 'Summoner') {
    return <StatusConfig[]>SummonerDebuffs;
  } if (specType === 'Warrior') {
    return <StatusConfig[]>WarriorDebuffs;
  } if (specType === 'WhiteMage') {
    return <StatusConfig[]>WhiteMageDebuffs;
  } if (specType === 'RedMage') {
    return <StatusConfig[]>RedMageDebuffs;
  } if (specType === 'Samurai') {
    return <StatusConfig[]>SamuraiDebuffs;
  } if (specType === 'Dancer') {
    return <StatusConfig[]>DancerDebuffs;
  } if (specType === 'Gunbreaker') {
    return <StatusConfig[]>GunbreakerDebuffs;
  } if (specType === 'Reaper') {
    return <StatusConfig[]>ReaperDebuffs;
  } if (specType === 'Sage') {
    return <StatusConfig[]>SageDebuffs;
  } if (specType === 'Viper') {
    return <StatusConfig[]>ViperDebuffs;
  } if (specType === 'Pictomancer') {
    return <StatusConfig[]>PictomancerDebuffs;
  }
  return [];
};

export const getAllJobDebuffConfig = ():JobDebuffConfig[] => {
  const allConfigs:JobDebuffConfig[] = [];
  SPEC_TYPE.forEach((specType, index) => {
    allConfigs.push({
      id: index,
      icon: specType,
      name: specType,
      abilities: getJobDebuffConfig(specType),
    });
  });
  return allConfigs;
};

export default {
  getAllDebuffConfig,
  getJobDebuffConfig,
  getAllJobDebuffConfig,
};
