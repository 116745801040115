import gql from 'graphql-tag';
import { query } from './client';

/**
 * https://ja.fflogs.com/v2-api-docs/ff/reportability.doc.html
 */
export interface ReportAbility {
  gameID: number
  icon: string
  name: string
  type: string
}

/**
 * https://ja.fflogs.com/v2-api-docs/ff/reportactor.doc.html
 */
export interface ReportActor {
  gameID: number
  id: number
  icon: string
  name: string
  server: string
  subType: string
  type: string
}
/**
 * https://ja.fflogs.com/v2-api-docs/ff/reportfight.doc.html
 */
export interface ReportFight {
  id: number
  name: string
  gameZone: {
    name: string
  }
  encounterID: number
  friendlyPlayers: number[]
  startTime: number
  endTime: number
}

/**
 * https://ja.fflogs.com/v2-api-docs/ff/reportmasterdata.doc.html
 */
export interface ReportMasterData {
  abilities: ReportAbility[]
  actors: ReportActor[]
}

export interface ReportRankingCharactor {
  id: number,
  name: string,
  class: string,
  spec: string,
  id_2?: number,
  name_2?: string,
  class_2?: string,
  spec_2?: string,
  server?: {
    id: number,
    name: string,
    region: string
  },
  amount: number,
  bracketData: number,
  bracket: number,
  rank: number,
  best: number,
  totalParses: number,
  bracketPercent: number,
  rankPercent: number
}

export interface ReportRankingRole {
  name: string,
  characters: ReportRankingCharactor[]
}

/**
 * JSON
 */
export interface ReportRanking {
  roles : {
    tank: ReportRankingRole
    healer: ReportRankingRole
    dps: ReportRankingRole
  }
}

/**
 * https://ja.fflogs.com/v2-api-docs/ff/report.doc.html
 */
export interface Report {
  code: string
  fights: ReportFight[]
  masterData: ReportMasterData
  rankings: {
    data: ReportRanking[]
  }
}

/**
 * JSON
 */
export interface ReportEvent {
  timestamp: number,
  type: string
  sourceID: number
  targetID: number
  sourceInstance: number
  abilityGameID: number
  fight: number
  extraAbilityGameID: number
  extraInfo: number
  duration: number
  stack: number
}

/**
 * JSON
 */
export interface ReportConbatantStatus {
  source: number,
  ability: number,
  stacks: number,
  icon: string
  name: string
}

/**
 * JSON
 */
export interface ReportCombatantInfo {
  timestamp: number,
  type: string
  sourceID: number
  gear: unknown[],
  auras: ReportConbatantStatus[],
  level: number,
  strength: number,
  dexterity: number,
  vitality: number,
  intelligence: number,
  mind: number,
  piety: number,
  attack: number,
  directHit: number,
  criticalHit: number,
  attackMagicPotency: number,
  healMagicPotency: number,
  determination: number,
  skillSpeed: number,
  spellSpeed: number,
  tenacity: number
}

/**
 * https://ja.fflogs.com/v2-api-docs/ff/reporteventpaginator.doc.html
 */
export interface ReportEventPaginator {
  data: ReportEvent[]
  nextPageTimestamp: number | null
}

export class ReportService {
  static async get(code: string, fightId: number): Promise<Report> {
    const result = await query(gql`
      query {
        reportData {
          report(code: "${code}" ) {
            code
            fights(fightIDs : ${fightId}, translate: true) {
              id
              name
              difficulty
              gameZone {
                name
              }
              encounterID
              friendlyPlayers
              startTime
              endTime
            }
            masterData(translate: true) {
              abilities {
                gameID
                icon
                name
                type
              }
              actors {
                gameID
                id
                icon
                name
                server
                subType
                type
              }
            }
            rankings(fightIDs : ${fightId})
          }
        }
      }
    `);

    console.log('report.get', result);
    return result.reportData.report;
  }

  static async actors(code: string): Promise<ReportActor[]> {
    const result = await query(gql`
      query {
        reportData {
          report(code: "${code}" ) {
            masterData(translate : true) {
              actors {
                gameID
                id
                icon
                name
                server
                subType
                type
              }
            }
          }
        }
      }
    `);

    console.log('report.actors', result);
    return result.reportData.report.masterData.actors;
  }

  static async conbatantInfo(
    code: string,
    startTime: number,
    endTime: number,
    sourceID: number,
  ) : Promise<ReportCombatantInfo> {
    const result = await query(gql`
      query {
        reportData {
          report(code: "${code}" ) {
            code
            events(
              startTime:${startTime},
              endTime:${endTime},
              sourceID: ${sourceID},
              dataType: CombatantInfo,
              translate: true
            ) {
              data
              nextPageTimestamp
            }
          }
        }
      }
    `);
    console.log('conbatantInfo', result);
    return result.reportData.report.events.data[0];
  }

  static async actorEvents(
    code: string,
    startTime: number,
    endTime: number,
    sourceID: number,
    dataType: 'Casts' | 'Buffs' | 'Debuffs' | 'CombatantInfo',
  ) : Promise<ReportEvent[]> {
    const paging = async (nextPageTimestamp: number): Promise<ReportEventPaginator> => {
      const result = await query(gql`
        query {
          reportData {
            report(code: "${code}" ) {
              code
              events(
                startTime:${nextPageTimestamp},
                endTime:${endTime},
                sourceID: ${sourceID},
                dataType: ${dataType},
                translate: true
              ) {
                data
                nextPageTimestamp
              }
            }
          }
        }
      `);
      console.log('actorEvents', result);
      return result.reportData.report.events;
    };

    const result: ReportEvent[] = [];
    let nextPageTimestamp: number | null = startTime;
    let page: ReportEventPaginator;

    while (nextPageTimestamp) {
      // eslint-disable-next-line no-await-in-loop
      page = await paging(nextPageTimestamp);
      result.push(...page.data);
      nextPageTimestamp = page.nextPageTimestamp;
    }
    return result;
  }

  static async enemiesEvents(
    code: string,
    startTime: number,
    endTime: number,
    dataType: 'CombatantInfo' | 'Casts' | 'Buffs' | 'Debuffs',
  ) : Promise<ReportEvent[]> {
    const paging = async (nextPageTimestamp: number): Promise<ReportEventPaginator> => {
      const result = await query(gql`
        query {
          reportData {
            report(code: "${code}" ) {
              code
              events(
                startTime: ${nextPageTimestamp},
                endTime: ${endTime},
                hostilityType: Enemies,
                dataType: ${dataType},
                translate: true
              ) {
                data
                nextPageTimestamp
              }
            }
          }
        }
      `);
      console.log('enemiesEvents', result);
      return result.reportData.report.events;
    };

    const result: ReportEvent[] = [];
    let nextPageTimestamp: number | null = startTime;
    let page: ReportEventPaginator;

    while (nextPageTimestamp) {
      // eslint-disable-next-line no-await-in-loop
      page = await paging(nextPageTimestamp);
      result.push(...page.data);
      nextPageTimestamp = page.nextPageTimestamp;
    }
    return result;
  }
}
