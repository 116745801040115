import { defineStore } from 'pinia';
import {
  reactive, toRefs, watch, ref,
} from 'vue';
import { db, SettingTable } from '@/database';
import { deepClone } from '@/utils';

const defaultSettings = <SettingTable>{
  sync: false,
};

export default defineStore('settings', () => {
  // 設定情報
  const settings: SettingTable = reactive(defaultSettings);

  // スクロール位置
  const scroll = ref(0);

  /**
   * DB更新処理
   */
  const update = async () => {
    db.table('settings').put({ id: 1, ...deepClone(settings) });
  };

  /**
   * 初期読込
   */
  const load = async () => {
    const row: SettingTable = await db.table('settings').get(1);
    Object.assign(settings, row);
    watch(settings, update, { deep: true });
  };

  load();
  return {
    ...toRefs(settings),
    scroll,
  };
});
