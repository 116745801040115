import { AbilitySettingData, BandsData, SettingsData } from '@/types/report.d';

export default class ReportSettings {
  public static create() : SettingsData {
    // 設定情報
    const settings = <SettingsData> {
      inherits: true,
      timescale: true,
      casts: {
        self: false,
        display: true,
        inherits: false,
        abilities: [],
        showname: false,
        showtime: false,
      },
      recasts: {
        self: false,
        display: true,
        inherits: false,
        abilities: [],
      },
      buffs: {
        self: false,
        display: false,
        inherits: false,
        abilities: [],
      },
      debuffs: {
        self: false,
        display: false,
        inherits: false,
        abilities: [],
      },
      enemies: {
        self: false,
        display: false,
        inherits: false,
        abilities: [],
        showname: false,
        showtime: false,
      },
      resources: {
        self: false,
        display: false,
        inherits: false,
        abilities: [],
      },
    };

    return settings;
  }

  public static createAbilitySettings(bands: BandsData[]) : number[] {
    const abilities: number[] = [];
    bands.forEach((band) => {
      abilities.push(band.gameID);
    });
    return abilities;
  }
}
