import { defineStore } from 'pinia';
import { reactive, toRefs } from 'vue';
import { GameService, GameSpec } from '@/services/game';
import { WorldService, Expansion } from '@/services/world';

interface MasterData {
  specs: GameSpec[]
  expansions: Expansion[]
}

export default defineStore('master', () => {
  const master: MasterData = reactive({
    specs: [],
    expansions: [],
  });

  const load = async () => {
    master.expansions = await WorldService.expansions();
    master.specs = await GameService.specs();
  };

  return {
    load,
    ...toRefs(master),
  };
});
