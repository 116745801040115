<template>
  <el-header height="50px">
    <div class="header__content">
      <div class="header__title">
        <span>1.0.9.2</span>
      </div>
      <div class="header__menu">
        <el-switch v-model="storeSettings.sync" class="ml-2"
          active-text="Sync Scroll"
        />
        <el-divider direction="vertical" />

        <el-button type="primary" class="ml-4" plain
            @click="addCompare"
        >
          <el-icon class="el-icon--left"><DocumentAdd /></el-icon>
          <span class="hidden-xs-only">New Project</span>
        </el-button>
        <el-button type="primary" class="ml-4" plain
            @click="showDrawer = true"
          >
          <el-icon class="el-icon--left"><FolderOpened /></el-icon>
          <span class="hidden-xs-only">Projects</span>
        </el-button>
      </div>
    </div>
  </el-header>
  <AppDrawer v-model:show="showDrawer" />
</template>

<script setup>
import { ref } from 'vue';
import { DocumentAdd, FolderOpened } from '@element-plus/icons-vue';
import AppDrawer from '@/components/Layout/AppDrawer.vue';
import useSettings from '@/store/settings';
import useProjects from '@/store/projects';

const storeSettings = useSettings();
const storeProjects = useProjects();

const addCompare = async () => {
  await storeProjects.addNewProject();
};

const showDrawer = ref(false);

</script>

<style scoped>
.header__content {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
}
.header__title {
  display: table;
  margin-left: -20px;
  height: 100%;
  color: var(--el-color-primary);
  font-weight: 800;
  /*color: var(--el-color-white);
  background-color: var(--el-color-primary);*/
  font-size: 28px;
  width: 200px;
}
.header__title span {
  padding-left: 30px;
  vertical-align: middle;
  display: table-cell;
}
.header__menu {
  margin-left:auto;
}
</style>
